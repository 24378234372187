<template>
  <div class="qrc-login">
    <img class="login-type" src="./image/login_mm.png" @click="onClickBackLogin"/>
    <div class="login-panel-title">扫码登录</div>
    <div class="qrc-box">
      <div class="qrc-con">

      </div>
      <a-divider class="or-line">or</a-divider>
      <div class="register-tip">还没有账号？<span class="register-btn" @click="onClickRegister">立即注册</span></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  created() {},
  mounted() {},
  methods: {
    onClickQrcLogin() {
      if (this.$parent.onClickQrcLogin) {
        this.$parent.onClickQrcLogin()
      }
    },
    onClickBackLogin() {
      if (this.$parent.onClickBackLogin) {
        this.$parent.onClickBackLogin()
      }
    }
  }
}
</script>

<style scoped>
  .qrc-login {
    width: 100%;
  }
  .qrc-login .login-panel-title {
    display: inline-block;
    position: relative;
    overflow: hidden;
    padding: 45px 0 20px 40px;
    font-size: 36px;
    font-weight: 400;
    color: #585858;
    line-height: 50px;
    letter-spacing: 1px;
  }
  .qrc-login .qrc-box {
    position: relative;
    width: 100%;
    padding: 0px 40px;
  }
  .qrc-login .qrc-box .qrc-con {
    width: 327px;
    height: 327px;
    margin: 0 auto;
    background: #1b2430;
  }
  .qrc-login .login-type {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  .qrc-login .or-line {
    margin: 10px 0;
    color: rgba(0, 0, 0, 0.41);
  }
  .qrc-login .register-tip {
    width: 377px;
    height: 69px;
    background: rgba(32, 53, 128, 0.01);
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.07);
    line-height: 69px;
    text-align: center;
    font-size: 18px;
  }
  .qrc-login .register-btn {
    color: #049AFA;
    cursor: pointer;
  }
</style>
