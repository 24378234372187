<template>
  <div class="login" @mouseenter="mousemove">
    <!--<img class="login-bg bg-d" src="./image/login_bg.png" :style="`left: ${left}px;top: ${top}px`">-->
    <img class="login-bg bg-d" :src="form.loginBackground||imgPath" :style="`left: ${left}px;top: ${top}px`">
    <!-- <img class="login-bg bg-t" src="./image/login_bg_f.png"> -->
   <img class="logo" v-show="form.loginShowLogo" :src="form.systemLogo||'./image/logo.svg'"/>
    <div class="sys-title">
      <div class="sys-name">
        <span>{{form.systemName}}</span>
        <span v-show="form.loginNameSuffix" class="extra">{{form.loginNameSuffix}}</span>
      </div>
      <!-- <div class="sys-enname"></div> -->
<!--      <div class="sys-cn">北京北大软件工程股份有限公司</div>-->
    </div>
    <div class="login-panel">
      <transition name="switch-fade">
        <component :is="isPanelCon"></component>
      </transition>
    </div>
    <div v-show="form.loginFoot" v-html="form.loginFoot">
    </div>
  </div>
</template>

<script>
import pwdLogin from './pwdLogin'
import register from './register'
import qrcLogin from './qrcLogin'

const bgCarousel = [
  {
    imgPath: 'bg00.png',
    delay: 15
  },
  {
    imgPath: 'bg02.png',
    delay: 15
  },
  {
    imgPath: 'bg03.png',
    delay: 15
  },
  {
    imgPath: 'bg04.png',
    delay: 15
  }
]
export default {
  components: { pwdLogin, register, qrcLogin },
  data() {
    return {
      isPanelCon: pwdLogin,
      positionX: 0,
      positionY: 0,
      left: 0,
      top: 0,
      imgPath: '',
      form:{
        systemLogo:'',
        loginShowLogo:'',
        loginBackground:'',
        loginFoot:'',
        loginNameSuffix:''
      }
    }
  },
  created() {
    this.getData()
    this.imgPath = this.getImgPath(0)
    // this.slideBgImg()
  },
  beforeDestroy() {
    // if(this.trim) this.trim.forEach((t) => {
    //   clearTimeout(t)
    // })
  },
  methods: {
    getData(){
      this.$http('post_showSystemConfig').then(res=>{
        this.form = res.data
      })
    },
    getImgPath(index) {
      try {
        return require('./image/' + bgCarousel[index].imgPath)
      } catch (e) {
        return false
      }
    },
    slideBgImg() {
      return false
      const total = bgCarousel.reduce((total, num) => (total.delay || total) + num.delay)
      let trim = this.trim = []
      const slideBg = () => {
        let trimTotal = 0
        if (trim.length) {
          trim.forEach((item) => {
            clearTimeout(item)
          })
          trim = []
        }
        bgCarousel.forEach((item, index) => {
          trimTotal += item.delay
          const itemTirm = setTimeout(() => {
            this.imgPath = this.getImgPath(index)
          }, trimTotal * 1000)
          trim.push(itemTirm)
        })
        const totalTrim = setTimeout(() => {
          slideBg()
        }, total * 1000)
        trim.push(totalTrim)
      }
      slideBg()
    },
    mousemove(e) {
      return false
      var x = e.clientX
      var y = e.clientY
      if (this.positionX === 0 && this.positionY === 0) {
        this.positionX = x
        this.positionY = y
      }
      if (x > this.positionX && y < this.positionY) {
        this.left = -10
        this.top = -11
      } else if (x > this.positionX && y > this.positionY) {
        this.left = -20
        this.top = -12
      } else if (x < this.positionX && y < this.positionY) {
        this.left = -10
        this.top = -5
      } else if (x < this.positionX && y > this.positionY) {
        this.left = -20
        this.top = -20
      }
      this.positionX = x
      this.positionY = y
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/.ant-form-vertical .ant-form-item{
    padding-bottom: 8px !important;
  } 
  /deep/.ant-form-item-children .ant-input{
    margin-top: -2px;
  }
  .login {
    position: relative;
    width: 100%;
    min-width: 1200px;
    height: 100%;
    overflow: hidden;
  }
  .login .logo {
    position: absolute;
    top: 30px;
    left: 50px;
    height: 40px;
  }
  .login .login-type {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  .login .login-panel {
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
    width: 457px;
    height: 570px;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
    box-sizing: content-box;
  }
  .login .login-footer{
    position: fixed;
    bottom: 0;
    height: 62px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(90deg,rgba(180,201,236,0),rgba(18,130,255,.9),rgba(180,201,236,0));
    color: #fff;
    &:before{
      margin-right: 20px;
      content: "";
      position: relative;
      width: 30px;
      height: 2px;
      background-color: #82b7e9;
    }
    &:after{
      margin-left: 20px;
      content: "";
      position: relative;
      width: 30px;
      height: 2px;
      background-color: #82b7e9;
    }
  }
  .login .login-bg {
    position: absolute;
    user-select: none;
    -webkit-user-select: none;
    cursor: default;
    pointer-events: none;
  }
  .login .bg-d {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    transition: all 1s;
  }
  .login .bg-t {
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .login .sys-title {
    position: absolute;
    top: 30%;
    left: 0;
    text-shadow: #173a7c 1px 1px 2px;
  }
  .login .sys-title .sys-name,
  .login .sys-title .sys-enname  {
    font-size: 65px;
    color: rgb(255, 255, 255);
    line-height: 79px;
    height: 200px;
    width: 1000px;
    background-image: linear-gradient(90deg, rgba(18, 130, 255, 0.5), rgba(180, 201, 236, 0));
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    padding-left: 3%;
    span{
      font-family: 宋体, serif;
    }
    .extra{
      font-size:30px;
      line-height:48px;
      padding: 0 10px;
      text-align: center;
      margin: 8px 0 0 5px;
      background-color: rgb(204, 86, 86);
      border-radius: 5px;
    }
  }
  .login .sys-title .sys-enname {
    width: 400px;
    text-transform: uppercase;
    line-height: 60px;
    margin: 20px 0;
  }
  .login .sys-title .sys-cn {
    font-size: 24px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.59);
    line-height: 33px;
    letter-spacing: 1px;
  }
  /* 切换动画 */
  .switch-fade-enter{
    opacity: 0;
    transform: translateX(-100%);
  }
  .switch-fade-leave-to{
    opacity: 0;
    transform: translateX(100%);
    // 解决页面从上往下位移问题
    position: absolute;
  }
  .switch-fade-enter-active,.switch-fade-leave-active{
    transition: all 0.35s ease;
  }
</style>
