<template>
  <div class="pwd-login">
    <!-- <img class="login-type" src="./image/login_em.png" @click="onClickQrcLogin"/> -->
    <div class="login-panel-title">账户登录</div>
    <div class="login-panel-form">
      <a-form-model layout="vertical" :model="formInline" :rules="loginRules" ref="ruleForm">
        <a-form-model-item class="input-label" prop="userName" ref="userName" label="账号">
          <a-input
            class="login-pwd"
            v-model="formInline.userName"
            @blur="() => { $refs.userName.onFieldBlur()}">
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="userPwd" ref="userPwd" label="密码">
          <a-input-password
            class="login-pwd"
            v-model="formInline.userPwd"
            type="password"
            @keydown.enter="beforeLogin"
            @blur="() => { $refs.userPwd.onFieldBlur()}">
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item label="验证码">
          <div class="code-box">
            <a-input
              class="login-pwd"
              @keyup.enter.native="beforeLogin"
              style="flex: 1"
              v-model="verifyCode">
            </a-input>
            <div class="code">
              <img :src="codePath" alt="验证码获取失败" style="width:100%;height:100%;cursor:pointer">
            </div>
          </div>
        </a-form-model-item>
      </a-form-model>
      <div class="opr-warp">
        <!-- <a-checkbox class="remember-pwd" @change="rememberPwd">记住密码</a-checkbox> -->
        <a-button class="login-btn" type="primary" @click="beforeLogin">登录</a-button>
      </div>
    </div>
    <!-- <div class="register-tip">还没有账号？<span class="register-btn" @click="onClickRegister">立即注册</span></div> -->
  </div>
</template>

<script>
import store from '@/micro/globalStore'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      codePath: '',
      verifyCode: '',
      verifyCodeKey: '123',
      formInline: {
        userName: 'admin',
        userPwd: ''
      },
      formModel: 'vertical',
      loginRules: {
        userName: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        userPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      redirect: undefined,
      otherQuery: {}
    }
  },
  computed: {
    ...mapState('global', {
      mainMenu: state => state.menu
    })
  },
  watch: {
    /**
       * 监听路由变化
       * */
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  mounted() {
    // 提取上次登录得账号
    this.$set(this.formInline, 'userName', localStorage.getItem('username'))
    // 如果密码字段在本地存在就提取出来
    if (localStorage.getItem('password') != null) {
      this.$set(this.formInline, 'userPwd', localStorage.getItem('password'))
    }
    this.refreshCode()
  },
  methods: {
    // 刷新验证码
    refreshCode() {
      this.$http('get_Logon_refreshCaptcha', { verifyCodeKey: this.verifyCodeKey }).then(res => {
        const { captchaKey, base64Img } = res.data
        this.verifyCodeKey = captchaKey
        this.codePath = base64Img
      })
    },
    /**
       * 登录前的验证
       * */
    beforeLogin() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.login()
        } else {
          this.$message.warning('请输入用户名或密码')
          return false
        }
      })
    },
    // 跳转配置
    listConfig() {
      this.$http('post_author_listConfigUser', { configCode: 'defaultPage' }, true).then(res => {
        console.log('sssssssssssss', res)
        if (res.code === 200 && res.data[0] && res.data[0].configValue) {
          const defaultIndex = res.data[0].configValue
          const { mainMenu } = this
          const ssMenuData = mainMenu.filter(item => item && item.name === '首页')
          if ((ssMenuData instanceof Array) && ssMenuData.length > 0) {
            const ssMenuItem = ssMenuData[0].children
            history.pushState(null, '/home', ssMenuItem[defaultIndex] && ssMenuItem[defaultIndex].path ? ssMenuItem[defaultIndex].path : ssMenuItem[0].path)
          } else {
            history.pushState(null, '/home', '/home')
          }
        } else {
          history.pushState(null, '/home', '/home')
          this.$router.push({
            path: '/home'
          })
        }
      })
    },
    /**
       * 登录操作
       * */
    login() {
      const userName = this.formInline.userName.trim()
      const userPwd = this.formInline.userPwd
      this.$store.dispatch('global/login', (encrypted) => {
        // console.log(encrypted(userPwd))
        return {
          userName: userName,
          passWord: encrypted(userPwd),
          verifyCodeKey: this.verifyCodeKey,
          verifyCode: this.verifyCode
        }
      }).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          const global = this.$store.state.global
          store.setGlobalState({
            token: global.token,
            userInfo: global.userInfo
          })
          this.$store.dispatch('global/dyrouters').then(res => {
            this.$store.commit('global/SET_MENU', res.data)
            this.listConfig()
          })
        } else {
          this.openNotificationWithIcon('error', '登录失败', res.message)
        }
      }).catch(err => {
        console.log(err)
        this.refreshCode()
        // this.$message.warning(err.message)
      })
    },
    /**
       * 信息提示调用
       * */
    openNotificationWithIcon(type, title, userName) {
      this.$notification[type]({
        message: title,
        description:
            `登录成功，欢迎 ${userName}`
      })
    },
    /**
       * 获取url其他参数
       * */
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    // 记住密码
    rememberPwd(e) {
      console.log(`checked = ${e.target.checked}`)
      localStorage.setItem('username', this.formInline.userName)
      if (e.target.checked) {
        localStorage.setItem('password', this.formInline.userPwd)
      } else {
        localStorage.removeItem('username')
        localStorage.removeItem('password')
      }
    },
    // 点击注册
    onClickRegister() {
      if (this.$parent.onClickRegister) {
        this.$parent.onClickRegister()
      }
    },
    onClickQrcLogin() {
      if (this.$parent.onClickQrcLogin) {
        this.$parent.onClickQrcLogin()
      }
    }
  }
}
</script>

<style lang="less" scoped>
    // .pwd-login {}
    .pwd-login .login-panel-title {
        display: inline-block;
        position: relative;
        overflow: hidden;
        padding: 45px 0 20px 40px;
        font-size: 36px;
        font-weight: 400;
        color: #585858;
        line-height: 50px;
        letter-spacing: 1px;
    }
    .pwd-login .login-panel-form {
        position: relative;
        width: 377px;
        padding: 40px 0px 60px;
        margin: 0 auto;
        /*padding: 15px 40px;*/
    }
    .pwd-login .login-type {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }
    .pwd-login .login-panel-form .login-pwd{
      font-size: 20px;
      height: 56px;
      background: rgba(32, 53, 128, 0.01);
      border-radius: 3px;
      border: 1px solid rgba(32, 53, 128, 0.16);
    }
    .pwd-login .login-panel-form .code-box{
      display: flex;
     .code{
        width:138px;
        border: 1px solid #ededed;
        margin-left:10px;
        margin-top:-2px;
        height: 56px;
      }
    }
    /deep/.ant-input-affix-wrapper .ant-input:not(:last-child) {
      font-size: 20px;
      height: 56px;
      background: rgba(32, 53, 128, 0.01);
      border-radius: 3px;
      border: 1px solid rgba(32, 53, 128, 0.16);
    }
    .pwd-login .login-panel-form /deep/ .ant-form-item-required {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.52);
    }
    .pwd-login .login-panel-form /deep/ .ant-form-item-required::before {
        display: none;
    }
    .pwd-login .opr-warp {
        position: relative;
        overflow: hidden;
        height: 45px;
        line-height: 45px;
        margin-top: 24px;
    }
    .pwd-login .opr-warp /deep/ .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
    .pwd-login .opr-warp /deep/ .ant-checkbox-inner::after {
        left: 30%;
        width: 7.714286px;
        height: 12.142857px;
    }
    .pwd-login .opr-warp .remember-pwd {
        font-size: 18px;
    }
    .pwd-login .login-btn {
        // float: right;
        right: 0;
        width: 100%;
        height: 45px;
        font-size: 20px;
        /*background: #51A2EE;*/
    }
    .pwd-login .login-panel-form-message {
        margin-top: 15px;
        p {
            margin: 3px 0;
            color: #000;
            /*font-size: @normalFontSize;*/
        }
    }
    .pwd-login .or-line {
        margin: 40px 0;
        color: rgba(0, 0, 0, 0.41);
    }
    .pwd-login .register-tip {
        width: 377px;
        height: 69px;
        background: rgba(32, 53, 128, 0.01);
        box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.07);
        line-height: 69px;
        text-align: center;
        font-size: 18px;
      margin: 0 auto;
    }
    .pwd-login .register-btn {
        color: #049AFA;
        cursor: pointer;
    }
    .pwd-login /deep/ .ant-checkbox-checked::after {
        border-radius: 50%;
    }
    .pwd-login /deep/ .ant-form-vertical .ant-form-item {
        margin-bottom: 0px;
        padding-bottom: 15px;
        label{
          font-size: 20px;
          color: rgba(0,0,0,.52);
        }
    }
    .pwd-login /deep/ .has-error .ant-form-explain {
        position: absolute;
        width: 100%;
        text-align: right;
    }
</style>
