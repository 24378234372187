<template>
  <div class="login-register">
    <a-icon class="back-login" type="right-circle" @click="onClickBackLogin" />
    <div class="login-panel-title">注册</div>
    <div class="login-panel-form">
      <a-form-model layout="vertical" :model="formInline" :rules="loginRules" ref="ruleForm">
        <a-form-model-item class="input-label" prop="userName" ref="userName" label="用户名/邮箱、手机号">
          <a-input
            class="login-user"
            v-model="formInline.userName"
            @blur="() => { $refs.userName.onFieldBlur()}">
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="userPwd" ref="userPwd" label="密码">
          <a-input
            class="login-pwd"
            v-model="formInline.userPwd"
            type="password"
            @keydown.enter="beforeLogin()"
            @blur="() => { $refs.userPwd.onFieldBlur()}">
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="vifcode" ref="vifcode" label="验证码">
          <a-input
            class="login-vcode"
            v-model="formInline.userPwd"
            @keydown.enter="beforeLogin()"
            @blur="() => { $refs.userPwd.onFieldBlur()}">
          </a-input>
        </a-form-model-item>
      </a-form-model>
      <div class="opr-warp">
        <a-button class="login-btn" type="primary" @click="beforeLogin()">注册</a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        userName: '',
        userPwd: '',
        vifcode: ''
      },
      formModel: 'vertical',
      loginRules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        userPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        vifcode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      redirect: undefined,
      otherQuery: {}
    }
  },
  watch: {
  },
  created() {},
  mounted() {},
  methods: {
    /**
       * 登录前的验证
       * */
    beforeLogin() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.login()
        } else {
          this.$message.warning('请输入用户名或密码')
          return false
        }
      })
    },
    /**
       * 登录操作
       * */
    login() {
      const userName = this.formInline.userName.trim()
      const userPwd = this.formInline.userPwd
      // eslint-disable-next-line no-unused-vars
      var userInfo = {}
      if (userName === 'admin' && userPwd === '123456') {
        userInfo = {
          userStatus: 1,
          userName: 'Admin'
        }
      } else if (userName === 'group' && userPwd === '123456') {
        userInfo = {
          userStatus: 2,
          userName: 'Group'
        }
      } else if (userName === 'test' && userPwd === '123456') {
        userInfo = {
          userStatus: 3,
          userName: '王栎鑫'
        }
      } else {
        this.$message.error('请输入正确的用户名或密码')
        return
      }
      console.log('ok')
      history.pushState(null, '/portal', '/portal')
      // this.$store.dispatch('user/userLogin', userInfo)
      //   .then(() => {
      //     const localColor = JSON.parse(localStorage.getItem(`${userInfo.userName}-themeColor`))
      //     if (localColor) {
      //       this.$store.dispatch('user/getThemeColor', localColor)
      //     }
      //     this.openNotificationWithIcon('success', '登录成功', userInfo.userName)
      //     this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
      //   })
    },
    /**
       * 信息提示调用
       * */
    openNotificationWithIcon(type, title, userName) {
      this.$notification[type]({
        message: title,
        description:
            `登录成功，欢迎 ${userName}`
      })
    },
    /**
       * 获取url其他参数
       * */
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    onChange() {},
    // 点击返回登录
    onClickBackLogin() {
      if (this.$parent.onClickBackLogin) {
        this.$parent.onClickBackLogin()
      }
    }
  }
}
</script>

<style scoped>
    .login-register {
    }
    .login-register .back-login {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 30px;
        cursor: pointer;
    }
    .login-register .login-panel-title {
        display: inline-block;
        position: relative;
        overflow: hidden;
        padding: 45px 0 20px 40px;
        font-size: 36px;
        font-weight: 400;
        color: #585858;
        line-height: 50px;
        letter-spacing: 1px;
    }
    .login-register .login-panel-form {
        position: relative;
        width: 100%;
        height: 300px;
        padding: 15px 40px;
    }
    .login-register .login-panel-form .login-user,
    .login-register .login-panel-form .login-pwd,
    .login-register .login-panel-form .login-vcode {
        font-size: 20px;
        height: 56px;
        background: rgba(32, 53, 128, 0.01);
        border-radius: 3px;
        border: 1px solid rgba(32, 53, 128, 0.16);
    }
    .login-register .login-panel-form /deep/ .ant-form-item-required {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.52);
    }
    .login-register .login-panel-form /deep/ .ant-form-item-required::before {
        display: none;
    }
    .login-register .opr-warp {
        overflow: hidden;
        height: 45px;
        line-height: 45px;
        margin-top: 24px;
        text-align: center;
    }
    .login-register .opr-warp .ant-checkbox-input {
    }
    .login-register .opr-warp /deep/ .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
    .login-register .opr-warp /deep/ .ant-checkbox-inner::after {
        left: 30%;
        width: 7.714286px;
        height: 12.142857px;
    }
    .login-register .login-btn {
        width: 189px;
        height: 45px;
        font-size: 20px;
        /*background: #51A2EE;*/
    }
    .login-register .login-panel-form-message {
        margin-top: 15px;
        p {
            margin: 3px 0;
            color: #000;
            /*font-size: @normalFontSize;*/
        }
    }
    .login-register /deep/ .ant-checkbox-checked::after {
        border-radius: 50%;
    }
    .login-register /deep/ .ant-form-vertical .ant-form-item {
        margin-bottom: 10px;
    }
    .login-register /deep/ .has-error .ant-form-explain {
        position: absolute;
        width: 100%;
        text-align: right;
    }
</style>
